/* eslint-disable react/no-danger */
import classnamesBind from 'classnames/bind';
import Link from '@concur/nui-widgets/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { withFormatter, getLanguage } from '@concur/nui-intl-runtime';
import {
    withErrorBoundary, getDataCenter, compose,
} from '@concur/core-ui-shell';
import ChinaICP from './_ChinaICP';
import CookiePreferences from './_CookiePreferences';
import FooterText from './_FooterText';
import LastLogin from './_LastLogin';
import Logo from '../Logo/Logo';
import styles from './AppFooter.css';

const CHINA_DATA_CENTER = 'china';
const CSS_BLOCK = 'sapcnqr-app-footer';

const Footer = (props) => {
    const {
        controllerPrivacyStatementUrl,
        country,
        dataCenterLocation,
        demo,
        formatter,
        isGov,
        isLoggedIn,
        isRetiredBrand,
        isTravelUser,
        lang,
        lastLogin,
        lastSigninDateString,
        location: { pathname } = {},
        partnerLogo,
        showOuttaskDevBar,
        supportLink,
        useSmallViewportBreakpoints,
    } = props;

    const classnames = classnamesBind.bind(styles);

    const dataCenter = getDataCenter(dataCenterLocation);
    const showCookieConsent = !isGov;
    // This variable changed from !isGov to false as per uicomp-3768
    // and will likely be changed back in the near future.
    const showPrivacyStatementLink = false;
    const showChinaLicense = dataCenter === CHINA_DATA_CENTER
        && !isLoggedIn
        && (!pathname || (pathname.indexOf('/profile') === -1 && pathname.indexOf('/v2/forgot') === -1));

    const classes = classnames(
        CSS_BLOCK,
        {
            [`${CSS_BLOCK}--signin`]: !isLoggedIn,
            [`${CSS_BLOCK}--fully-responsive`]: useSmallViewportBreakpoints,
            [`${CSS_BLOCK}--china`]: showChinaLicense,
        },
    );

    const sectionClasses = classnames(
        `${CSS_BLOCK}__section`,
    );

    const logoSectionClasses = classnames(
        `${CSS_BLOCK}__section`,
        `${CSS_BLOCK}__section--logo`,
        {
            [`${CSS_BLOCK}__section--signin`]: !isLoggedIn,
        },
    );

    const sapConcurLogoClasses = classnames(
        `${CSS_BLOCK}__sap-concur`,
    );

    const getServiceStatusUrl = () => {
        const queryString = dataCenter ? `?data-center=${dataCenter}` : '';
        return `//open.concur.com/${queryString}`;
    };

    // eslint-disable-next-line no-unused-vars
    const getPrivacyPolicyUrl = () => {
        const langPrefix = lang ? lang.substring(0, 2) : 'en';
        switch (langPrefix) {
        case 'fr':
            return '//www.concur.fr/processor-privacy-statement';
        case 'de':
            return '//www.concur.de/processor-privacy-statement';
        case 'it':
            return '//www.concur.it/processor-privacy-statement';
        default:
            return '//www.concur.com/en-us/processor-privacy-statement';
        }
    };

    const getCookieStatementUrl = () => {
        const supportedLangs = [
            'bg',
            'cs',
            'da',
            'de',
            'es',
            'es-la',
            'en-gb',
            'fi',
            'fr',
            'fr-ca',
            'hr',
            'hu',
            'id',
            'it',
            'ja',
            'ko',
            'nl',
            'no',
            'pl',
            'pt-br',
            'ro',
            'ru',
            'sk',
            'sv',
            'th',
            'tr',
            'uk',
            'zh-cn',
            'zh-tw',
        ];

        const langUrl = supportedLangs.includes(getLanguage(lang)) ? getLanguage(lang) : 'en';
        const cdnUrl = dataCenter === CHINA_DATA_CENTER ? 'static.concurcdn.cn' : 'static.concursolutions.com';
        return `https://${cdnUrl}/about/legal/privacy/${langUrl}/`;
    };

    return (
        <div className={classes} role="contentinfo">
            <section className={logoSectionClasses} data-test="app-footer-logos">
                <div className={sapConcurLogoClasses}>
                    {!partnerLogo && (
                        <Logo
                            className={classnames(`${CSS_BLOCK}__logo`, {
                                [`${CSS_BLOCK}__logo--retired`]: isRetiredBrand,
                            })}
                            styles={styles}
                            cssBlock={CSS_BLOCK}
                            isRetiredBrand={isRetiredBrand}
                        />
                    )}

                    {partnerLogo && <div dangerouslySetInnerHTML={{ __html: partnerLogo }} />}
                </div>
            </section>
            <section className={sectionClasses} data-test="app-footer-links">
                <div className={classnames(`${CSS_BLOCK}__items`)}>
                    {showPrivacyStatementLink && (
                        <Link
                            className={classnames(`${CSS_BLOCK}__link`)}
                            href={getPrivacyPolicyUrl()}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.processorPrivacyStatement' })}
                        </Link>
                    )}

                    {controllerPrivacyStatementUrl && !isGov && (
                        <Link
                            className={classnames(`${CSS_BLOCK}__link`)}
                            data-test="controller-privacy-statement"
                            href={`${controllerPrivacyStatementUrl}${controllerPrivacyStatementUrl.indexOf('?') !== -1 ? '&' : '?'}lang=${lang ? lang.substring(0, 2) : 'en'}`}
                            rel="noreferrer noopener"
                            target="_blank"
                            data-analytics-json={'{"applicationName":"footer","elementPath":"privacy-statement","action":"click","elementType":"link"}'}
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.controllerPrivacyStatement' })}
                        </Link>
                    )}

                    {isTravelUser && (
                        <Link
                            className={classnames(`${CSS_BLOCK}__link`)}
                            data-height="540"
                            data-name="policy"
                            data-toggle="popupwindow"
                            data-url="/twPopup/travel_rules_popup.asp"
                            data-width="600"
                            href="/twPopup/travel_rules_popup.asp"
                            target="policy"
                            data-analytics-json={'{"applicationName":"footer","elementPath":"travel-policy","action":"click","elementType":"link"}'}
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.travelPolicy' })}
                        </Link>
                    )}

                    <Link
                        className={classnames(`${CSS_BLOCK}__link`)}
                        href={getServiceStatusUrl()}
                        target="open"
                        data-analytics-json={'{"applicationName":"footer","elementPath":"service-status","action":"click","elementType":"link"}'}

                    >
                        {formatter.formattedMessage({ id: `CoreUI.serviceStatus_${!dataCenter ? 'all' : dataCenter}` })}
                    </Link>

                    {supportLink && (
                        <Link
                            className={classnames(`${CSS_BLOCK}__link`)}
                            href={supportLink}
                            target={supportLink?.indexOf?.('window.open') === -1 ? '_blank' : undefined}
                            data-analytics-json={'{"applicationName":"footer","elementPath":"contact-support-link","action":"click","elementType":"link"}'}
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.contactSupport' })}
                        </Link>
                    )}

                    {showCookieConsent && formatter?.preferences?.lang && (
                        <CookiePreferences
                            country={country}
                            demo={demo}
                            lang={lang}
                        />
                    )}
                    {showCookieConsent && (
                        <Link
                            className={classnames(`${CSS_BLOCK}__link`)}
                            href={getCookieStatementUrl()}
                            data-analytics-json={'{"applicationName":"footer","elementPath":"cookie-statement-link","action":"click","elementType":"link"}'}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.cookieStatement' })}
                        </Link>
                    )}
                </div>
                <div className={classnames(`${CSS_BLOCK}__items`)}>
                    {showChinaLicense && (
                        <ChinaICP styles={styles} cssBlock={CSS_BLOCK} />
                    )}

                    {(lastLogin || lastSigninDateString) && (
                        <LastLogin
                            styles={styles}
                            cssBlock={CSS_BLOCK}
                            lastLogin={lastLogin}
                            lastSigninDateString={lastSigninDateString}
                        />
                    )}

                    <FooterText cssBlock={CSS_BLOCK} styles={styles}>
                        {formatter.formattedMessage({ id: 'CoreUI.copyright', values: { year: new Date().getFullYear() } })}
                    </FooterText>
                </div>
            </section>

            {showOuttaskDevBar && (
                <section className={sectionClasses} data-test="app-footer-devbar">
                    <iframe
                        className={classnames(`${CSS_BLOCK}__devbar`)}
                        frameBorder="0"
                        id="devBarFrame"
                        scrolling="no"
                        src="/devbar.asp"
                        title="devbar"
                    />
                </section>
            )}
        </div>
    );
};

Footer.displayName = 'Footer';

Footer.cssBlock = CSS_BLOCK;

Footer.propTypes = {
    controllerPrivacyStatementUrl: PropTypes.string,
    country: PropTypes.string,
    dataCenterLocation: PropTypes.string,
    demo: PropTypes.bool,
    isGov: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isTravelUser: PropTypes.bool,
    isRetiredBrand: PropTypes.bool,
    lang: PropTypes.string,
    lastLogin: PropTypes.string, // Deprecated -- use lastSigninDateString instead
    lastSigninDateString: PropTypes.string,
    location: PropTypes.object,
    partnerLogo: PropTypes.string,
    showOuttaskDevBar: PropTypes.bool,
    supportLink: PropTypes.string,
    useSmallViewportBreakpoints: PropTypes.bool,
};

Footer.defaultProps = {
    lang: 'en',
    showOuttaskDevBar: false,
};

export default compose(
    withFormatter,
    withErrorBoundary,
)(Footer);
