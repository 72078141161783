/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
import classnamesBind from 'classnames/bind';
import Icon from '@concur/nui-widgets/Icon';
import Tooltip from '@concur/nui-widgets/Tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import Link from '@concur/nui-widgets/Link';
import useUniqueId from '@concur/nui-widgets/lib/utils/useUniqueId';
import { lowerCase } from '@concur/core-ui-shell';
import Menu from './_Menu';
import { TRANSLATION_TOOL_URL_IDENTIFIER } from '../constants';

const renderListItemChild = (
    cssBlock,
    children,
    styles,
    formatter,
    hasPopover,
    testId,
    submenuHeadingId,
    headingId,
    labelId,
    isOverflow,
    isSubItem,
    name,
    additionalAnchorProps,
    dropdownProps,
    anchorSpanProps,
    subItems,
    tooltipMessage,
    doNotDescribe,
) => {
    const classnames = classnamesBind.bind(styles);

    if (children) {
        const linkContent = (
            <Link
                {...additionalAnchorProps}
                {...dropdownProps}
                role="menuitem"
                aria-describedby={headingId || labelId}
            >
                {children}
            </Link>
        );
        return (
            tooltipMessage ? (
                <Tooltip
                    delay={1000}
                    placement={['bottom', 'top', 'right', 'left']}
                    message={tooltipMessage}
                    doNotDescribe={doNotDescribe}
                    outerPopperProps={{
                        showArrow: false,
                        offset: 2,
                    }}
                    innerContainerProps={{
                        className: classnames(`${cssBlock}__tooltip-inner`),
                    }}
                >
                    {linkContent}
                </Tooltip>
            ) : linkContent
        );
    }

    const iconName = isSubItem ? 'arrow-1-e' : 'arrow-1-s';
    const iconClasses = classnames(
        `${cssBlock}__icon`,
        {
            [`${cssBlock}__icon--squash`]: !isSubItem,
            [`${cssBlock}__icon--subitem`]: isSubItem,
        },
    );

    if (subItems || isOverflow) {
        return (
            <span
                {...dropdownProps}
                data-test={testId ? `menu__button-${lowerCase(testId)}` : null}
                role="button"
                id={submenuHeadingId}
                tabIndex={hasPopover ? '0' : '-1'}
            >
                {isOverflow ? (<Icon ariaLabel={formatter?.formattedMessage({ id: 'CoreUI.moreLinksLabel' })} className={classnames(`${cssBlock}--overflow`)} iconName="menu" size="md" />) : name}
                {!isOverflow && subItems && (
                    <>
                        {' '}
                        <Icon ariaHidden className={iconClasses} iconName={iconName} />
                    </>
                )}
            </span>
        );
    }

    return (
        <Link
            {...additionalAnchorProps}
            aria-describedby={headingId || labelId}
        >
            {name}
            <span {...anchorSpanProps} />
        </Link>
    );
};

const MenuItem = React.forwardRef((props, ref) => {
    const {
        anchorClassName,
        anchorProps,
        children,
        className,
        cssBlock,
        depth,
        expanded,
        formatter,
        hasPopover,
        id,
        headingId,
        isActive,
        isDivider,
        isFioriProductMenu,
        isHeading,
        isHidden,
        isOverflow,
        isPipe,
        isSubItem,
        isSubItemMenuAlignRight,
        isSubItemMenuUtility,
        labelId,
        name,
        onClick,
        onMouseEnter,
        parentMenuCssBlock,
        subItems,
        styles,
        target,
        url,
        tooltipMessage,
        doNotDescribe,
        ...otherProps
    } = props;

    const classnames = classnamesBind.bind(styles);

    // do not render the Translation Tool menu item
    // it has to stay in the menuData because it is used
    // to determine if the user has the permission, but
    // just don't render it anymore
    if (url?.indexOf(TRANSLATION_TOOL_URL_IDENTIFIER) >= 0) {
        return null;
    }

    if (isDivider) {
        return <hr aria-hidden="true" className={classnames(`${cssBlock}__divider`)} />;
    }

    if (isPipe) {
        return <li role="separator" className={classnames(`${cssBlock}__pipe`)} />;
    }

    if (isHeading) {
        return (
            <li
                {...otherProps}
                aria-hidden
                className={classnames(className, `${cssBlock}__heading`)}
                role="presentation"
                id={id}
            >
                {children}
            </li>
        );
    }

    const classes = classnames(`${cssBlock}__listitem`, className, {
        [`${cssBlock}__listitem--hidden`]: isHidden,
    });

    const anchorClasses = classnames(`${cssBlock}__anchor`, anchorClassName, {
        [`${cssBlock}__anchor--active`]: isActive && !isSubItem,
        [`${cssBlock}__anchor--submenu`]: !!subItems,
        [`${cssBlock}__anchor--expanded`]: expanded,
    });

    const anchorSpanClasses = classnames(`${cssBlock}__anchor-span`);

    // if any menu items are trying to use window.open, the target attribute needs to be removed
    const isTargetAttributeSupported = () => !url?.match(/window\.open/);

    const additionalAnchorProps = {
        ...anchorProps,
        'aria-current': isActive ? 'page' : undefined,
        'aria-label': name,
        className: anchorClasses,
        innerTextClasses: classnames(`${cssBlock}__linktext`),
        'data-test': id ? `menu__anchor-${lowerCase(id)}` : null,
        href: url,
        target: isTargetAttributeSupported() ? target : null,
        tabIndex: '0',
        'data-active': isActive,
    };

    const dropdownProps = subItems && {
        className: anchorClasses,
        'data-test': id ? `menu__dropdown-${lowerCase(id)}` : null,
        onClick,
        onMouseEnter,
        'aria-expanded': expanded,
    };

    const anchorSpanProps = {
        className: anchorSpanClasses,
    };

    const subItemMenuClasses = classnames(`${cssBlock}__submenu`, {
        [`${cssBlock}__submenu--expanded`]: expanded,
        [`${cssBlock}__submenu--right`]: isSubItemMenuAlignRight || isOverflow,
        [`${cssBlock}__submenu--utility`]: isSubItemMenuUtility,
        [`${cssBlock}__submenu--level${depth}`]: depth,
    });

    const submenuHeadingId = useUniqueId();

    return (
        <li
            aria-hidden={isHidden ? true : undefined}
            {...otherProps}
            className={classes}
            data-test={id ? `menu__listitem-${lowerCase(id)}` : null}
            ref={ref}
            aria-describedby={labelId}
        >
            {renderListItemChild(
                cssBlock,
                children,
                styles,
                formatter,
                hasPopover,
                id,
                submenuHeadingId,
                headingId,
                labelId,
                isOverflow,
                isSubItem,
                name,
                additionalAnchorProps,
                dropdownProps,
                anchorSpanProps,
                subItems,
                tooltipMessage,
                doNotDescribe,
            )}
            {subItems && !isOverflow && (
                <Menu
                    className={subItemMenuClasses}
                    id={id}
                    parentMenuCssBlock={parentMenuCssBlock}
                    isFioriProductMenu={isFioriProductMenu}
                >
                    {subItems.map((item, index) => (
                        <MenuItem
                            {...item}
                            parentMenuCssBlock={parentMenuCssBlock}
                            styles={styles}
                            cssBlock={cssBlock}
                            depth={depth + 1}
                            key={`subItem${index}`}
                            hasPopover={hasPopover}
                            isSubItem
                            isSubItemMenuUtility={isSubItemMenuAlignRight}
                            labelId={submenuHeadingId}
                        />
                    ))}
                </Menu>
            )}
            {subItems && isOverflow && (
                <Menu
                    ariaLabel={name}
                    className={subItemMenuClasses}
                    parentMenuCssBlock={parentMenuCssBlock}
                    isFioriProductMenu={isFioriProductMenu}
                >
                    {React.Children.map(subItems, (child) => React.cloneElement(child, { isSubItem: true, hasPopover }))}
                </Menu>
            )}
        </li>
    );
});

MenuItem.displayName = 'MenuItem';

MenuItem.apiPropTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    isDivider: PropTypes.bool,
    subItems: PropTypes.arrayOf(PropTypes.object),
    target: PropTypes.string,
};

MenuItem.defaultProps = {
    depth: 1,
};

MenuItem.propTypes = {
    ...MenuItem.apiPropTypes,
    anchorClassName: PropTypes.string,
    anchorProps: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    cssBlock: PropTypes.string,
    depth: PropTypes.number,
    hasPopover: PropTypes.bool,
    headingId: PropTypes.string,
    isHeading: PropTypes.bool,
    isHidden: PropTypes.bool,
    isOverflow: PropTypes.bool,
    isSubItem: PropTypes.bool,
    isPipe: PropTypes.bool,
    isSubItemMenuAlignRight: PropTypes.bool,
    labelId: PropTypes.string,
};

export default MenuItem;
